/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { AjaxService } from './ajax.service';
import { BrowserKeys, Environment, FloorAPIPath, ProjectAPIPath, ReportAPIPath, SiteAPIPath, UserAPIPath } from '../constants';
import { AuthService } from './auth.service';
import { CommonService } from './common.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Project } from '../model/project';
import { Utilities } from '../utilities';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private ajaxService: AjaxService,
    private authService: AuthService, private readonly env: Environment,
    private http: HttpClient,
    private commonService: CommonService) { }
  getUsers() {
    return this.ajaxService.get(`${ProjectAPIPath.projectUsers}/users`);
  }
  getProjectsList(): any {
    return this.ajaxService.get(ProjectAPIPath.projectsList);
  }

  uploadProjectCsvDevice(file: any, isPreview: boolean): any {
    const formdata = new FormData();
    formdata.append("file", file);
    return this.ajaxService.post(`${ReportAPIPath.deviceCsvUpload}?isPreview=${isPreview ? 'true' : 'false'}&isProject=true`, formdata);
  }

  uploadProjectImage(images: any) {
    return this.ajaxService.post(`${ProjectAPIPath.image}`, images);
  }
  getProjectsDesign(): any {
    return this.ajaxService.get(ProjectAPIPath.projectsList + '?stage=Design');
  }
  getProjectsDesignCount(): any {
    return this.ajaxService.get(ProjectAPIPath.project + '/archive/count?stage=Design');
  }
  getSitesIdAndName() {
    return this.ajaxService.get(SiteAPIPath.siteDropdownList);
  }
  getProjectsInstallation(): any {
    return this.ajaxService.get(ProjectAPIPath.projectsList + '?stage=Installation');
  }
  getProjectsInstallationCount(): any {
    return this.ajaxService.get(ProjectAPIPath.project + '/archive/count?stage=Installation');
  }
  getProjectInformation(projectId: any) {
    return this.ajaxService.get(`${ProjectAPIPath.project}/${projectId}/information`);
  }
  getProjectPermission(projectId: any) {
    return this.ajaxService.get(`${ProjectAPIPath.project}/${projectId}/permission`);
  }
  getProjectStatus(projectId: any) {
    return this.ajaxService.get(`${ProjectAPIPath.project}/${projectId}/status`);
  }
  getSwtichConnection(id: any, buildingId: any, floorId: any) {
    return this.ajaxService.get(`${FloorAPIPath.switchFloors}?projectId=${id}&buildingId=${buildingId}&floorId=${floorId}`);
  }
  getFiles(projectId: any) {
    return this.ajaxService.get(`${ProjectAPIPath.project}/${projectId}/file`);
  }
  downloadDailyReport(projectId: any, floorIds: any) {
    const data = 'Bearer ' + this.commonService.getLocalStorageString(BrowserKeys.token)
    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': data,
        'customerId': this.authService.getCustomerId().customerId.toString()
      }),
      reportProgress: true,
      // responseType: 'text'
    };

    httpOptions['responseType'] = 'text'
    let req: any;
    if (floorIds && floorIds.length > 0) {
      req = new HttpRequest('PUT', `${this.env.apiPath}${ProjectAPIPath.project}/${projectId}/dailyreport`, floorIds, httpOptions);
    } else {
      req = new HttpRequest('PUT', `${this.env.apiPath}${ProjectAPIPath.project}/${projectId}/dailyreport`, {}, httpOptions);
    }
    return this.http.request(req);
  }
  downloadPdf(projectId: any, options: any) {
    const data = 'Bearer ' + this.commonService.getLocalStorageString(BrowserKeys.token);

    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': data,
        'customerId': this.authService.getCustomerId().customerId.toString()
      }),
      reportProgress: true,
      // responseType: 'text'
    };

    httpOptions['responseType'] = 'text'
    let url = this.env.apiPath;
    if (this.env.envName === 'Staging' || this.env.envName === 'live') {
      url = this.env.reportApiPath;
    }
    const req = new HttpRequest('POST', `${url}${ProjectAPIPath.project}/${projectId}/overview_pdf`, options, httpOptions);

    return this.http.request(req);
  }
  addFloor(projectId: any, floorids: any) {
    return this.ajaxService.post(`${ProjectAPIPath.project}/${projectId}/floors`, floorids);
  }
  publishProject(projectId: any) {
    return this.ajaxService.put(`${ProjectAPIPath.project}/${projectId}/publishProject`, null)
  }
  unpublishProject(projectId: any) {
    return this.ajaxService.post(`${ProjectAPIPath.project}/${projectId}/unpublish`, null)
  }
  removeFloor(projectId: any, floorId: any) {
    return this.ajaxService.delete(`${ProjectAPIPath.project}/${projectId}/floors/${floorId}`);
  }
  removeUserProjectPermission(data: any, isFromProfile?: any) {
    const url = isFromProfile ? `${UserAPIPath.user}/${data.user_id}/project/${data.project_id}/permission` : `${ProjectAPIPath.project}/${data.project_id}/user/${data.user_id}/permission`;
    return this.ajaxService.delete(url, {});
  }
  getProjectAccessRoles(): any {
    return this.ajaxService.get(ProjectAPIPath.projectAccessRole);
  }
  updateUserProjectPermission(data: any) {
    return this.ajaxService.put(`${ProjectAPIPath.project}/${data.project_id}/permission`, data);
  }
  saveProjectInformation(projectInfo: any, prevSelectedCustomerId?: any) {
    return this.ajaxService.put(`${ProjectAPIPath.projectInformation}`, projectInfo, { headers: { customerId: prevSelectedCustomerId } });
  }
  createProjectInformation(data: Project): any {
    return this.ajaxService.post(`${ProjectAPIPath.project}/information`, data);
  }
  getDevicePart(projectId: any, reqBody: any) {
    return this.ajaxService.post(`${ProjectAPIPath.project}/${projectId}/report/partlist`, reqBody)
  }
  deleteFileFromProject(projectId: any, fileId: any) {
    return this.ajaxService.delete(`${ProjectAPIPath.project}/${projectId}/file/${fileId}`);
  }
  getProjectFilePresignedURL(reqData: any) {
    return this.ajaxService.get(ProjectAPIPath.presigned + Utilities.objectToParams(reqData));
  }
  uploadFile(url: string, formData: any) {
    const uploadContentType = 'multipart/form-data';
    return this.ajaxService.put(url, formData, { headers: { 'Content-Type': uploadContentType } }, { removeAuthToken: true });
  }
  saveProjectFiles(files: any) {
    return this.ajaxService.post(`${ProjectAPIPath.saveFiles}`, files);
  }
  checkCopyLinkAcess(projectId: any, customerId: any) {
    return this.ajaxService.get(`${ProjectAPIPath.projectAccess}/${projectId}/access?customerId=` + customerId);
  }
  addResourceToProject(user: any) {
    return this.ajaxService.post(UserAPIPath.addResource, user)
  }

  getProjectsInstallationArchive(): any {
    return this.ajaxService.get(ProjectAPIPath.projectlist + '?stage=Installation&isArchive=true');
  }

  getProjectsDesignArchive(): any {
    return this.ajaxService.get(ProjectAPIPath.projectlist + '?stage=Design&isArchive=true');
  }

  projectStageActionStatus(projectId: any, stage: any) {
    return this.ajaxService.get(`${ProjectAPIPath.projects}/${projectId}/stage?stage=${stage}`);
  }
  archiveUnarchiveProjects(id: number, isArchived: any): any {
    return this.ajaxService.get(ProjectAPIPath.projects + '/' + id + '/archived?isArchived=' + isArchived);
  }
  duplicateProject(projectId: any, projectName: any) {
    return this.ajaxService.put(`${ProjectAPIPath.projectAccess}/${projectId}/createduplicate?projectName=` + encodeURIComponent(projectName), {});
  }
  deleteProject(id: number): any {
    return this.ajaxService.delete(`${ProjectAPIPath.project}/${id}`);
  }

  downloadDeviceImages(projectId: any, floorIds: any) {
    const data = 'Bearer ' + this.commonService.getLocalStorageString(BrowserKeys.token);

    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': data,
        'customerId': this.authService.getCustomerId().customerId.toString()
      }),
      reportProgress: true,
      // responseType: 'text'
    };

    httpOptions['responseType'] = 'text'

    const req = new HttpRequest('PUT', `${this.env.apiPath}${ProjectAPIPath.project}/${projectId}/project_device_images`, floorIds, httpOptions);

    return this.http.request(req);
  }

  multiPlanPrintOut(id: any, params: any, isFromSite: any) {
    const data = 'Bearer ' + this.commonService.getLocalStorageString(BrowserKeys.token);
    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': data,
        'customerId': this.authService.getCustomerId().customerId.toString()
      }),
      reportProgress: true,
      // responseType: 'text'
    };

    httpOptions['responseType'] = 'text'

    let url = this.env.apiPath;
    if (this.env.envName === 'Staging' || this.env.envName === 'live') {
      url = this.env.reportApiPath;
    }
    url += ProjectAPIPath.project + '/' + id + '/multiPlanPrint';
    const req = new HttpRequest('POST', `${url}`, params, httpOptions);

    return this.http.request(req);
  }

  getCategories() {
    return this.ajaxService.get(`${ProjectAPIPath.category}`);
  }
  moveBuilding(sourceProjectId: any, targetProjectId: any, buildingId: any) {
    return this.ajaxService.get(ProjectAPIPath.projects + '/movebuilding?sourceProject=' + sourceProjectId + '&tragetProject=' + targetProjectId + '&buildingId=' + buildingId);
  }
  getProgressData(chartType: any, projectId: any, floorId?: any, categoryId?: any, buildingId?: any, isPrimary?: any, actionType?: any) {
    let url: any = ProjectAPIPath.project + '/' + projectId + '/progress';
    if (floorId || categoryId || buildingId || isPrimary !== '') {
      let subUrl: any = '';
      if (floorId) {
        subUrl += subUrl === '' ? 'floorId=' + floorId : '&floorId=' + floorId;
      }
      if (categoryId) {
        subUrl += subUrl === '' ? 'categoryId=' + categoryId : '&categoryId=' + categoryId;
      }
      if (buildingId) {
        subUrl += subUrl === '' ? 'buildingId=' + buildingId : '&buildingId=' + buildingId;
      }
      if (isPrimary !== undefined) {
        subUrl += subUrl === '' ? 'isPrimary=' + isPrimary : '&isPrimary=' + isPrimary;
      }
      if (actionType !== undefined) {
        subUrl += subUrl === '' ? 'action=' + actionType : '&action=' + actionType;
      }
      if (subUrl !== '') {
        url += '?' + subUrl + '&chartType=' + chartType;
      } else {
        url += '?chartType=' + chartType;
      }
    }
    return this.ajaxService.get(url);
  }

  setProjectUserFlagNotification(data: any, projectId: any) {
    return this.ajaxService.put(`${ProjectAPIPath.project}/${projectId}/flagNotification`, data)
  }

  getProjectById(projectId: any, customerId?: any) {
    if (customerId && customerId !== '' && customerId !== null && customerId !== undefined) {
      return this.ajaxService.get(`${ProjectAPIPath.project}/${projectId}`, { headers: { 'customerId': customerId } });
    }
    return this.ajaxService.get(`${ProjectAPIPath.project}/${projectId}`);
  }
}
