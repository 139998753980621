<div (blur)="onTouched()" class="multiselect-dropdown" (clickOutside)="closeDropdown()">
  <div [class.disabled]="disabled">
    <span tabindex="-1" class="dropdown-btn" (click)="toggleDropdown($event)">
      <span *ngIf="selectedItems.length === 0">{{_placeholder}}</span>
      <span *ngIf="selectedItems.length ===_data.length && selectedItems.length > 0">All {{customMessage}}</span>
      <span *ngIf="selectedItems.length <_data.length && selectedItems.length > 0">{{selectedItems.length}} {{customMessage}} Selected</span>
      <span
        style="float:right !important;padding-right:4px">
        <span class="dropdown-multiselect__caret"></span>
      </span>
    </span>
  </div>
  <div class="dropdown-list" [hidden]="!_settings.defaultOpen">
    <ul class="item1">
      <li (click)="toggleSelectAll()"
        *ngIf="(_data.length > 0 || _settings.allowRemoteDataSearch) && !_settings.singleSelection && _settings.enableCheckAll && _settings.limitSelection===-1"
        class="multiselect-item-checkbox" style="border-bottom: 1px solid #ccc;padding:10px">
        <input type="checkbox" aria-label="multiselect-select-all" [checked]="isAllItemsSelected()"
          [disabled]="disabled || isLimitSelectionReached()" />
        <div>{{!isAllItemsSelected() ? _settings.selectAllText : _settings.unSelectAllText}}</div>
      </li>
      <li class="filter-textbox"
        *ngIf="(_data.length>0 || _settings.allowRemoteDataSearch) && _settings.allowSearchFilter">
        <input type="text" aria-label="multiselect-search" [readOnly]="disabled"
          [placeholder]="_settings.searchPlaceholderText" [(ngModel)]="filter.text"
          (ngModelChange)="onFilterTextChange($event)">
      </li>
    </ul>
    <ul class="item2" [style.maxHeight]="_settings.maxHeight+'px'">
      <li *ngFor="let item of _data | multiSelectFilter:filter; let i = index;" (click)="onItemClick($event,item)"
        class="multiselect-item-checkbox">
        <input type="checkbox" [attr.aria-label]="item.text" [checked]="isSelected(item)"
          [disabled]="disabled || (isLimitSelectionReached() && !isSelected(item)) || item.isDisabled" />
        <div>{{item.text}}</div>
      </li>
      <li class='no-filtered-data'
        *ngIf="_data.length !== 0 && (_data | multiSelectFilter:filter).length === 0 && !_settings.allowRemoteDataSearch">
        <h5>{{_settings.noFilteredDataAvailablePlaceholderText}}</h5>
      </li>
      <li class='no-data' *ngIf="_data.length === 0 && !_settings?.allowRemoteDataSearch">
        <h5>{{_settings.noDataAvailablePlaceholderText}}</h5>
      </li>
    </ul>
  </div>
</div>