/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeviePriority, AppService, ReportService, Utilities, EquipmentLocationList, EquipmentIPTypeList, ChipsOptions, Priorities, Types, Statuses, IPPattern, MACPattern, Environment, EventEmitterType, EventService } from '@SiteOwl/core';
import { Component, Input, Output, EventEmitter, forwardRef, ViewChild, ElementRef, HostListener, OnDestroy, OnInit, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { InlineArchiveDevice } from '../../constant/inline-archive.constant';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CommonPopupComponent } from '../common-popup/common-popup.component';

const INLINE_EDIT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InlineEditComponent),
  multi: true
};

export enum KEY_CODE {
  ESC = 27,
  ENTER = 13
}

@Component({
  selector: 'so-inline-edit',
  templateUrl: './inline-edit.component.html',
  providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
  styleUrls: ['./inline-edit.component.scss'],
})

export class InlineEditComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @ViewChild('input') input: any;

  @Input() data: any;  // Label value for input element
  @Input() type!: string; // The type of input element
  @Input() required!: boolean; // Is input requried?
  @Input() disabled!: boolean; // Is input disabled?
  @Input() isError!: boolean; // Is input disabled?
  @Input() page!: string;
  @Input() customerUsers: any;
  @Output() focusLost = new EventEmitter<any>();
  @Input() watchers: any;
  @Input() newWatcher: any;
  @Input() canEdit!: boolean;
  @Input() details: any;
  @Input() otherData: any;
  @Input() originalSelectedWatcher: any;
  @Input() originalUserList: any;
  @Input() archivedName!: any
  @Output() valueChange = new EventEmitter<any>();
  @Output() valueChangeArchive = new EventEmitter<any>();
  @Output() valueUpdate = new EventEmitter<any>();
  bsModalRef!: BsModalRef;
  optionsL: any = {
    attributes: null,
    className: 'linkified',
    defaultProtocol: 'http',
    events: null,
    format: (value: any, type: any) => {
      return value;
    },
    formatHref: (href: any, type: any) => {
      return href;
    },
    ignoreTags: [],
    nl2br: false,
    tagName: (value: any, type: any) => {
      if (type === 'url') {
        return 'a';
      } else {
        return 'span'
      }
    },
    target: {
      url: '_blank'
    },
    validate: true
  };
  private preValue = ''; // The value before clicking to edit
  editing = false; // Is Component in edit mode?
  public onChange: any = Function.prototype; // Trascend the onChange event
  public onTouched: any = Function.prototype; // Trascend the onTouch event

  isErrorText = 'Name is required';
  isErrorTextSub = 'Subject is required';
  chipsOption = ChipsOptions;
  options: any = {
    dateFormat: 'mm/dd/yyyy',
  };
  dueDatePickerOptions: any = {
    dateFormat: 'mm/dd/yyyy'
  };

  selector: any = {
    open: false
  }
  filterFieldForm!: FormGroup

  selectOptions: any[] = [];
  amtDeviceStatusOption = [
    { value: 'Operational', label: 'Operational' },
    { value: 'OperationalWithIssue', label: 'Operational with Issue' },
    { value: 'NotWorking', label: 'Not Working' }
  ];
  projectDeviceStatusOption = [
    { value: 'Installed', label: 'Installed/Done' },
    { value: 'InProgress', label: 'In Progress' },
    { value: 'Planned', label: 'Planned' }
  ];
  deviceTypeOptions = [
    { value: 'device', label: 'Device' },
    { value: 'task', label: 'Task' },
  ];
  devicePriorityOptions = [
    { value: DeviePriority.critical, label: DeviePriority.critical },
    { value: DeviePriority.medium, label: DeviePriority.medium },
    { value: DeviePriority.low, label: DeviePriority.low },

  ];
  ticketPriotiryOptions = [
    { value: Priorities.High, label: Priorities.High },
    { value: Priorities.Medium, label: Priorities.Medium },
    { value: Priorities.Low, label: Priorities.Low },

  ];

  ticketTypeOptions = [
    { value: Types.Repair, label: Types.Repair },
    { value: Types.General, label: Types.General },
    { value: Types.Maintenance, label: Types.Maintenance },

  ];
  ticketStatusOptions = [
    { value: Statuses.Open, label: Statuses.Open },
    { value: Statuses.InProgress, label: Statuses.InProgress },
    { value: Statuses.Closed, label: Statuses.Closed },

  ];
  autoUpdateDeviceStatusOptions = [
    { value: "By Monitoring Status", label: "By Monitoring Status" },
    { value: "Don't Auto-Update", label: "Don't Auto-Update" }
  ];
  deviceArchiveOptions = [
    { value: false, label: "Active" },
    { value: true, label: "Archived" }
  ];
  closeSub!: Subscription;
  lastValue: any = null;
  archiveSub!: Subscription;
  deviceStatusData: any;
  cdnUrl = this.env.cdnUrl;
  assigneeUser: any[] = [];
  siteId!: any;

  constructor(private appService: AppService, private reportService: ReportService, private readonly env: Environment, private eventService: EventService,
    private element: ElementRef, private modalService: BsModalService) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['otherData'] && this.filterFieldForm !== undefined) {
      this.filterFieldForm.controls['value'].setValue(changes['data'].currentValue['record'][this.data.key]);
    }
    if (this.data.comp === 'ticket') {
      this.options = this.dueDatePickerOptions;
    }
    if (this.type === 'tagInput') {
      if (this.data.key === 'watchers') {
        this.setWatchersList();
      }
    }
  }
  @HostListener('keyup', ['$event'])
  keyEvent(event: any) {
    if (event.keyCode === KEY_CODE.ESC) {
      this.filterFieldForm.get('value')?.setValue(this.preValue);
      this.editing = false;
    }

    if (event.keyCode === KEY_CODE.ENTER && event.ctrlKey) {
      if (this.type !== 'date') {
        event.target.blur()
      }
    }

    if (event.keyCode === KEY_CODE.ENTER) {
      if (this.type !== 'textarea') {
        event.target.blur()
      }
    }

    if (this.data['key'] === 'ipAddress' && !IPPattern.exec(this.filterFieldForm.controls['value'].value) && this.filterFieldForm.controls['value'].value !== '') {
      this.isError = true;
      this.isErrorText = 'Invalid IP Address';
    } else if (this.data['key'] === 'ipAddress' && (this.filterFieldForm.controls['value'].value === '' || IPPattern.exec(this.filterFieldForm.controls['value'].value))) {
      this.isError = false;
    } else if (this.data['key'] === 'macAddress' && !MACPattern.exec(Utilities.formatMACAddress(this.filterFieldForm.controls['value'].value)) && this.filterFieldForm.controls['value'].value !== '') {
      this.isError = true;
      this.isErrorText = 'Invalid MAC Address';
    } else if (this.data['key'] === 'macAddress' && (this.filterFieldForm.controls['value'].value === '' || MACPattern.exec(Utilities.formatMACAddress(this.filterFieldForm.controls['value'].value)))) {
      this.isError = false;
    } else if (this.data['key'] === 'coverageDirection' && (this.filterFieldForm.controls['value'].value < 0 || this.filterFieldForm.controls['value'].value > 360)) {
      this.isError = true;
      this.isErrorText = 'Coverage Direction should greater than 0 and less than 361';
    } else if (this.data['key'] === 'coverageAngle' && (this.filterFieldForm.controls['value'].value < 0 || this.filterFieldForm.controls['value'].value > 360)) {
      this.isError = true;
      this.isErrorText = 'Coverage Angle should greater than 0 and less than 361';
    } else if (this.data['key'] === 'coverageRange' && (this.filterFieldForm.controls['value'].value < 0 || this.filterFieldForm.controls['value'].value > 100)) {
      this.isError = true;
      this.isErrorText = 'Coverage Range should greater than 0 and less than 101';
    } else if (this.data['key'] === 'flagNotes' && (Utilities.isNull(this.filterFieldForm.controls['value'].value) || Utilities.isEmpty(this.filterFieldForm.controls['value'].value) || this.filterFieldForm.controls['value'].value === undefined)) {
      this.isError = true;
      this.isErrorText = 'Flag Notes is required';
    }

    if (this.required && this.filterFieldForm.controls['value'].value.trim() === '' && this.data.comp !== 'ticket') {
      this.isError = true;
      this.isErrorText = 'Name is required';
      this.filterFieldForm.controls['value'].setValue('');
    } else if (this.required && this.filterFieldForm.controls['value'].value.trim() === '' && this.data.comp === 'ticket') {
      this.isError = true;
      this.isErrorText = 'Subject is required';
      this.filterFieldForm.controls['value'].setValue('');
    } else if (this.required) {
      this.isError = false;
    }
  }

  writeValue(value: any) {
    this.filterFieldForm.controls['value'].setValue(value);
  }

  // Required forControlValueAccessor interface
  public registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  // Required forControlValueAccessor interface
  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  // Do stuff when the input element loses focus
  onBlur(event: Event) {
    if (this.filterFieldForm.controls['value'].value === 'true') {
      this.filterFieldForm.controls['value'].setValue(true);
    }
    if (this.filterFieldForm.controls['value'].value === 'false') {
      this.filterFieldForm.controls['value'].setValue(false);

    }
    if (this.isError && (this.data['key'] === 'ipAddress' || this.data['key'] === 'coverageAngle' || this.data['key'] === 'coverageDirection' || this.data['key'] === 'coverageRange' || this.data['key'] === 'macAddress')) {
      this.filterFieldForm.controls['value'].setValue(this.preValue);
      this.isError = false;
    }
    if (this.data['key'] === 'macAddress' && !Utilities.isNull(this.filterFieldForm.controls['value'].value)) {
      const macFormattedValue = Utilities.formatMACAddress(this.filterFieldForm.controls['value'].value);
      this.filterFieldForm.controls['value'].setValue(macFormattedValue);
    }
    if (this.required && this.filterFieldForm.controls['value'].value === '') {
      this.isError = true;
      this.filterFieldForm.controls['value'].setValue(this.preValue);;
    }
    if (this.required && this.filterFieldForm.controls['value'].value !== '') {
      this.isError = false;
    }
    if (this.required && this.filterFieldForm.controls['value'].value === '') {
      this.isError = true;
      this.editing = true;
      return;
    }

    if (this.preValue === this.filterFieldForm.controls['value'].value) {
      this.editing = false;
      this.isError = false;
      return;
    }

    const newData = this.data;
    if (typeof this.filterFieldForm.controls['value'].value === 'string') {
      newData['value'] = Utilities.removeWhiteSpace(this.filterFieldForm.controls['value'].value);// checking whitespace remove fn if string
    } else {
      newData['value'] = this.filterFieldForm.controls['value'].value;
    }

    if (this.type === 'number' && this.data['key'] !== 'port') {
      if (isNaN(newData['value']) || newData['value'] < 0) {
        newData['value'] = this.preValue;
        this.filterFieldForm.controls['value'].setValue(this.preValue);
        this.editing = false;
      }
    }

    if (this.type === 'dropdown') {
      newData['value'] = newData['value'] === '' ? null : newData['value'];
      if (this.data.comp === 'ticket' && this.data['key'] === 'requesterById') {
        if (this.data.id === this.details.id) {
          this.details.requesterById = this.data.value;
          if (this.data.options.filter((x: any) => x.user.id === this.data.value)) {
            this.details.requesterBy = this.data.options.filter((x: any) => x.user.id === this.details.requesterById)[0];
            if (this.details.requesterBy.name) {
              this.details.requester = this.details.requesterBy.name
            } else {
              this.details.requester = this.details.requesterBy.email;
            }
          }
        }
        this.updateOptionsForRequester(this.data, false);
      }
    }
    this.disabled = true;
    if (this.data.comp === 'ticket') {
      this.page = 'ticket'
    }
    if (this.data['key'] !== 'isArchived') {
      this.saveInLineChanges(newData);
    } else if (this.data['key'] === 'isArchived') {
      this.updateArchiveStatus(newData)
    }

  }
  updateArchiveStatus(newData: any) {
    if (newData.value) {
      const dataReplacer = {
        '{{#name}}': _.escape(newData.archivedName),
      }
      const modalData: any = Utilities.contentReplacer(dataReplacer, _.cloneDeep(InlineArchiveDevice));
      const initialState: ModalOptions = {
        initialState: modalData, class: InlineArchiveDevice.dialogClass, ignoreBackdropClick: true
      };
      this.bsModalRef = this.modalService.show(CommonPopupComponent, initialState);
      this.bsModalRef.content.event.subscribe((res: any) => {
        if (res !== 'Cancel') {
          this.saveInLineChanges(newData);
        } else {
          this.setPreviousValue();
        }
        this.bsModalRef?.hide();
      })
    } else {
      this.saveInLineChanges(newData);
    }
  }
  saveInLineChanges(newData?: any) {
    // if (newData['key'] === "isArchived" && (newData['value'] === "false" || newData['value'] === "true")) {
    //   newData['value'] = newData['value'] === "true"
    // }
    if (newData['key'] === 'networkSwitchLinkName') {
      newData['key'] = 'networkSwitchLinkId';
    }
    if (newData['comp'] === 'ticket') {
      this.page = 'ticket'
    }
    if (newData['key'] === "coverageDirection") {
      newData['value'] = parseInt(newData['value'])
      if (newData['value'] > -1 && newData['value'] < 361) {
        this.callSaveChanges(newData)
      } else {
        this.setPreviousValue();
      }
    } else if (newData['key'] === "coverageAngle") {
      newData['value'] = parseInt(newData['value'])
      if (newData['value'] > 0 && newData['value'] < 361) {
        this.callSaveChanges(newData)
      } else {
        this.setPreviousValue();
      }
    } else if (newData['key'] === "coverageRange") {
      newData['value'] = parseInt(newData['value'])
      if (newData['value'] > 0 && newData['value'] < 101) {
        this.callSaveChanges(newData)
      } else {
        this.setPreviousValue();
      }
    } else if (newData['key'] === "flagNotes") {
      newData['value'] = newData['value'].trimStart();
      if (Utilities.isNull(newData['value']) || Utilities.isEmpty(newData['value']) || newData['value'] === undefined) {
        this.setPreviousValue()
      } else {
        this.callSaveChanges(newData)
      }
    } else if (newData['key'] === "installHours" || newData['key'] === "price" || newData['key'] === "budgetedCost" || newData['key'] === "cost" || newData['key'] === "height") {
      newData['value'] = newData['value'].trimStart();
      if (this.validDecimalNumber(newData['value'])) {
        this.setPreviousValue()
      } else {
        this.callSaveChanges(newData)
      }
    } else {
      if (typeof newData['value'] === 'string') {
        newData['value'];
      }
      if (!(Utilities.isNull(newData['value']) || Utilities.isEmpty(newData['value']) || newData['value'] === undefined) || newData['value'] !== this.preValue) {
        if (typeof newData['value'] === 'string') {
          this.filterFieldForm.controls['value'].setValue(Utilities.removeWhiteSpace(this.filterFieldForm.controls['value'].value));
        }
        this.callSaveChanges(newData)
      } else {
        if (typeof newData['value'] === 'string') {
          this.filterFieldForm.controls['value'].setValue(Utilities.removeWhiteSpace(this.filterFieldForm.controls['value'].value));
        }
        this.editing = false;
        this.disabled = false;
        this.isError = false;
      }
    }

  }

  setPreviousValue() {
    if (typeof this.preValue === 'string') {
      this.filterFieldForm.controls['value'].setValue(Utilities.removeWhiteSpace(this.preValue));
    } else {
      this.filterFieldForm.controls['value'].setValue(this.preValue);
    }
    this.editing = false;
    this.disabled = false;
    // this.eventService.broadcast(EventEmitterType.DataUpdated, this.data);
    this.isError = false;
  }
  callSaveChanges(newData: any) {

    const newSendData = _.omit(newData, 'options'); //It will return a new object
    this.reportService.inlineChangeDevicefields(newSendData, this.page).subscribe({
      next: ((response: any) => {
        this.disabled = false;
        if (this.page === 'audit') {
          this.preValue = newData['value']
          this.eventService.broadcast(EventEmitterType.auditDataUpdated, {});
        }
        if (this.type === 'tagInput') {
          this.editing = true;
        } else {
          this.editing = false;
        }
        if (newData['key'] === 'name' || newData['key'] === 'subject') {
          this.filterFieldForm.controls['value'].setValue(_.cloneDeep(newData['value']));
        }
        if (newData['key'] === 'networkSwitchLinkId') {
          const valueOfselectedOption = this.selectOptions.filter(e => e.value === newData['value']);
          this.valueChange.emit({ valueOfselectedOption: valueOfselectedOption, response: response, customerUsers: this.customerUsers, dataUpdated: response, comp: this.data.comp });
        } else if (newData['key'] === 'isArchived') {
          this.valueChangeArchive.emit({ valueOfselectedOption: newData, response: response, customerUsers: this.customerUsers, dataUpdated: response, comp: this.data.comp });
        } else {
          this.valueUpdate.emit({ valueOfselectedOption: newData, dataUpdated: response, customerUsers: this.customerUsers, comp: this.data.comp });
        }
        if (newData['key'] === 'macAddress') {
          this.data.monitoredStatus = response.monitoredStatus;
          this.data.status = response.status;
        }
        // this.eventService.broadcast(EventEmitterType.DataUpdated, this.data);
        if (!this.watchers || (this.watchers && this.watchers.length == 0)) {
          this.customerUsers = [];
          this.customerUsers = this.originalUserList;
          this.customerUsers = _.uniqBy(this.customerUsers, 'id');
        }
        this.isError = false;
      }), error: (error: any) => {
        this.isError = true;
        this.editing = true;
        this.disabled = false;
        // this.eventService.broadcast(EventEmitterType.DataUpdated, this.data);

        if (error.status === 409 || error.status === 422) {
          this.isErrorText = error.error.message;
        } else {
          this.isErrorText = 'Error while saving';
        }
      }
    });
  }
  // Start the editting process for the input element
  edit(value: any) {

    if (this.data['key'] === 'coverageDirection' && !Utilities.isEmpty(this.otherData) && !this.otherData.coverageArea) {
      return;
    }
    if (this.disabled) {
      return;
    }
    if (this.data['key'] === 'networkSwitchLinkName') {
      this.selectOptions = [];
      this.disabled = true;
      if (this.page === 'project') {
        this.reportService.getNetworkSwitchDevice(this.data.projectId, false, this.data.buildingId).subscribe({
          next: (res: any) => {
            this.setNetworkSwitchDropdown(res, value);
          }
        });
      } else if (this.page === 'site') {
        this.reportService.getNetworkSwitchDevice(this.data.buildingId, true).subscribe({
          next: (res: any) => {
            this.setNetworkSwitchDropdown(res, value);
          }
        });
      }
      else {
        this.editFeild(value);
      }
    } else if (this.data.key === 'assignedToId') {
      this.selectOptions = [];
      this.disabled = true;
      if (this.data.comp === 'ticket') {
        if (this.siteId !== this.data.siteId) {
          this.siteId = this.data.siteId;
          this.reportService.getAssignedSitesUser(this.data.siteId).subscribe((res: any) => {
            this.assigneeUser = res;
            this.setAssgineUserDropdown(res, value);
          });
        } else {
          this.setAssgineUserDropdown(this.assigneeUser, value);
        }
      }
    } else if (this.data.key === 'installedByUser' && this.data.comp === 'sites') {
      this.selectOptions = [];
      this.disabled = true;
      if (this.siteId !== this.data.siteId) {
        this.siteId = this.data.siteId;
        this.reportService.getAssignedSitesUser(this.data.siteId).subscribe((res: any) => {
          this.assigneeUser = res;
          this.setAssgineUserDropdown(res, value);
        });
      } else {
        this.setAssgineUserDropdown(this.assigneeUser, value);
      }
    }
    else {
      this.editFeild(value);
    }


  }
  editFeild(value: any) {
    const element = document.getElementById('inlineEditor') as HTMLElement;
    element.click();

    this.preValue = value;
    this.editing = true;

    if (this.type === 'text' || this.type === 'number' || this.type === 'textarea' || this.type === 'dropdown') {
      const timeOut = setTimeout(() => {
        const element = document.getElementById(this.filterFieldForm.controls['value'].value) as HTMLElement;
        if (element) {
          element.focus();
        }
        clearTimeout(timeOut)
      });
    } else if (this.type === 'date') {
      setTimeout(() => {
        const element = document.getElementById('datepicker_' + this.filterFieldForm.controls['value'].value) as HTMLElement;
        if (element) {
          element.click();
          element.focus();
        }
      }, 0);
      this.isError = false;
      this.selector.open = true;

    } else if (this.type === 'tagInput') {
      const timeOut = setTimeout(() => {
        if (this.input)
          this.input.inputForm.input.nativeElement.focus();
        clearTimeout(timeOut)
      }, 0);
      if (value != '') {
        this.setWatchersList();

      } else {
        this.watchers = [];
      }

    }
  }
  dateCalendarFocusBlur(event: any) {
    if (event.reason === 2) {
      this.filterFieldForm.controls['value'].setValue(this.preValue);
      this.editing = false;
    }
  }

  dateCalendarChanged(event: any) {
    const newData = this.data;
    newData['value'] = event.data === '' || event.data === 'Invalid date' ? null : event.data;
    this.disabled = true;
    if (this.data.comp === 'ticket') {
      this.page = 'ticket'
    }
    const newSendData = _.omit(newData, 'options'); //It will return a new object
    this.reportService.inlineChangeDevicefields(newSendData, this.page).subscribe({
      next: ((response: any) => {
        this.disabled = false;
        this.editing = false;
        this.filterFieldForm.controls['value'].setValue(event.data === '' || event.data === 'Invalid date' ? null : event.data);
        this.valueUpdate.emit({ valueOfselectedOption: newData, dataUpdated: response, customerUsers: this.customerUsers, comp: this.data.comp });
        this.isError = false;
      }), error: ((error: any) => {
        this.isError = true;
        this.editing = true;
        this.disabled = false;
        this.filterFieldForm.controls['value'].setValue(event.data === '' || event.data === 'Invalid date' ? null : event.data);
        if (error.status === 409 || error.status === 422) {
          this.isErrorText = error.error.message;
        } else {
          this.isErrorText = 'Error while saving';
        }
      })
    });
  }


  dateCalendarFieldChanged(event: any) {
    if (!event.valid && event.value !== '') {
      this.isError = true;
      this.isErrorText = 'Enter date in MM/DD/YYYY format';
    } else {
      this.isError = false;
      this.isErrorText = '';
    }
  }

  ngOnInit() {
    this.filterFieldForm = new FormGroup({
      value: new FormControl('')
    });
    if (this.page !== 'audit' && (this.deviceStatusData === undefined || this.deviceStatusData === null || this.deviceStatusData.length === 0)) {
      this.appService.getStatusData().subscribe(r => {
        this.deviceStatusData = r
      })
    }
    if (this.type === 'dropdown') {
      if (this.data.key === 'result' || this.page === 'audit') {
        this.selectOptions = this.data.options;
      }
      if (this.data.key === 'coverageArea') {
        this.selectOptions.push({ label: 'Yes', value: true });
        this.selectOptions.push({ label: 'No', value: false });

      } else if (this.data.key === 'deviceLocation') {
        this.selectOptions.push({ label: 'Select Location', value: '' });
        EquipmentLocationList.forEach(x => {
          this.selectOptions.push({ label: x, value: x });
        });
      } else if (this.data.key === 'dataType') {
        this.selectOptions.push({ label: 'None', value: null });
        EquipmentIPTypeList.forEach(x => {
          this.selectOptions.push({ label: x, value: x });
        });
      } else if (this.data.key === 'installedByUser' || this.data.key === 'assignedToId' || this.data.key === 'assignedToUser') {
        this.selectOptions.push({ label: 'Unassigned', value: null, isRemoved: false });
        this.data.options.forEach((x: any) => {
          this.selectOptions.push({ label: x.name || x.email, value: x.id, isRemoved: x.isNotToShow });
        });
      } else if (this.data.key === 'requesterById') {
        this.updateOptionsForRequester(this.data);
      } else if (this.data.key === 'status') {
        if (this.data.comp === 'ticket') {
          this.selectOptions = this.ticketStatusOptions
        } else {
          this.deviceStatusData.forEach((element: any, index: any) => {
            if (index < 3) {
              this.selectOptions.push({
                value: element.status,
                label: element.displayStatus
              })
            }
          });
        }
      } else if (this.data.key === 'installStatus') {
        this.selectOptions = this.projectDeviceStatusOption;
      } else if (this.data.key === 'type') {
        if (this.data.comp === 'ticket') {
          this.selectOptions = this.ticketTypeOptions;
        } else {
          this.selectOptions = this.deviceTypeOptions;
        }
      } else if (this.data.key === 'priority' && this.data.comp === 'ticket') {
        this.selectOptions = this.ticketPriotiryOptions;
      } else if (this.data.key === 'priority') {
        this.selectOptions = this.devicePriorityOptions;
      } else if (this.data.key === 'autoUpdateDeviceStatus') {
        this.selectOptions = this.autoUpdateDeviceStatusOptions;
      } else if (this.data.key === 'isArchived') {
        this.selectOptions = this.deviceArchiveOptions;

      }

    }
    else if (this.type === 'tagInput') {
      if (this.data.key === 'watchers') {
        this.setWatchersList();
      }
    }
  }
  setNameFormId() {
    if (this.filterFieldForm.controls['value'].value !== null && this.filterFieldForm.controls['value'].value !== 'null' && this.filterFieldForm.controls['value'].value !== '') {
      if (this.data.key === 'installedByUser' || this.data.key === 'assignedToUser') {
        const data = this.data.options.find((x: any) => x.id === Number(this.filterFieldForm.controls['value'].value));
        return Utilities.isNull(data) ? '' : data['name'] || data['email'];
      } else if (this.data.key === 'coverageArea') {
        return this.selectOptions.find(x => x.value === this.filterFieldForm.controls['value'].value)['label'];
      } else {
        try {
          return this.selectOptions.find(x => x.value == this.filterFieldForm.controls['value'].value)['label'];
        } catch (e) {
        }
      }
    } else {
      if (this.data.key === 'installedByUser' || this.data.key === 'assignedToUser' || this.data.key === 'assignedToId') {
        return 'Unassigned'
      } else if (this.data.key === 'dataType') {
        return 'None';
      }
    }
  }

  closeFromOutSide(className: any) {
    if (this.type === 'date') {
      if (className && className.indexOf('mydpicon') === -1 &&
        className.indexOf('weekdaytitle') === -1 &&
        className.indexOf('selection') === -1) {

        if (!Utilities.isNull(this.filterFieldForm.controls['value'].value) && !Utilities.isNull(this.filterFieldForm.controls['value'].value.date)) {
          this.filterFieldForm.controls['value'].setValue(this.preValue);
        }
        this.editing = false;
      }
    }
  }

  ngOnDestroy() {
    if (!Utilities.isNull(this.filterFieldForm.controls['value'].value) && !Utilities.isNull(this.filterFieldForm.controls['value'].value.date)) {
      this.filterFieldForm.controls['value'].setValue(this.preValue);

    }
    if (this.isError) {
      this.filterFieldForm.controls['value'].setValue(this.preValue);
    }
    if (this.archiveSub) {
      this.archiveSub.unsubscribe()
    }
    if (this.closeSub) {
      this.closeSub.unsubscribe();
    }

  }


  /**
   * Tag edit,add, and remove
   */
  onInputBlurred(event: any) {
    this.addWatcher(event)
  }
  removeTags(input: any, item: any, index: any) {
    const removeItem = item;
    input.removeItem(item, index);
    this.removeWatcher(removeItem)
  }

  addWatcher(event: any) {

    const watcher = {
      id: event.value.id,
      isDeleted: false,
      isNew: true,
      serviceTicketId: this.data.id,
      value: event.value,
      display: event.value.email,
      watcherId: event.value.id,
      watcher: event.value
    }
    if (this.editing && this.watchers.some((watcher: any) => watcher.watcherId === event.value.id)) {
      this.watchers.find((user: any) => user.watcherId === event.value.id).isDeleted = false;
    }
    else {
      if (this.watchers.length > 0) {
        this.watchers.forEach((element: any, index: any) => {
          if (element.id === event.value.id) {
            this.watchers.splice(index, 1);
          }
        });
      }
      this.watchers.push(watcher)
      this.originalSelectedWatcher.push(watcher);
    }
    this.customerUsers.forEach((element: any, index: any) => {
      if (element.value.id === event.value.id) {
        this.customerUsers.splice(index, 1);
      }
    });
    this.page = 'ticket'
    const newData = this.data;
    newData['value'] = event.value.id;
    this.customerUsers = _.uniqBy(this.customerUsers, 'id');

    this.setWatchersList();

    this.saveInLineChanges(newData);
  }
  removeWatcher(item: any) {
    if (item.value) {
      this.customerUsers.push({ value: item.value, display: item.display, id: item.id })
    } else {
      this.customerUsers.push({ value: item, display: item.email, id: item.id })
    }

    if (this.editing && item.value) {
      this.watchers.forEach((element: any, index: any) => {
        if (element.watcherId === item.value.id) {
          element.isDeleted = true;
        }
      });
    }
    else if (this.editing && (this.watchers.some((user: any) => user.watcherId === item.id))) {
      this.watchers.forEach((element: any, index: any) => {
        if (element.watcherId === item.id) {
          element.isDeleted = true;
        }
      });
    }
    else {
      this.watchers.forEach((element: any, index: any) => {
        if (element.watcherId === item.id) {
          this.watchers.splice(index, 1);
        }
      });
    }
    this.originalSelectedWatcher.forEach((element: any, index: any) => {
      if (element.watcherId === item.id) {
        this.originalSelectedWatcher.splice(index, 1);
      }
    });

    this.page = 'ticket'
    const newData = this.data;
    newData['value'] = item.id;
    this.customerUsers = _.uniqBy(this.customerUsers, 'id');
    this.setWatchersList();
    this.saveInLineChanges(newData);
  }

  setWatchersList() {
    this.watchers = [];
    if (this.siteId !== this.data.siteId) {
      this.customerUsers = [];
      this.originalUserList = [];
      this.siteId = this.data.siteId;
      this.reportService.getAssignedSitesUser(this.data.siteId).subscribe((res: any) => {
        res.forEach(((user: any) => {
          _.cloneDeep(this.originalUserList.push({ id: user.id, value: user, display: user.email }));
        }))
        this.originalUserList.forEach((user: any) => {
          this.customerUsers.push(user)
        })
        const selected = _.filter(this.originalSelectedWatcher, {
          serviceTicketId: this.newWatcher
        });
        this.originalUserList.forEach((user: any) => {
          selected.forEach((element: any) => {
            if (user.value.id === element.watcherId) {
              this.watchers.push({ value: user.value, display: user.value.email, id: user.value.id });
            }
          });
        });
        if (this.watchers.length > 0) {
          this.customerUsers.forEach((element: any, index: any) => {
            if (this.watchers.some((x: any) => x.id === element.id)) {
              this.customerUsers.splice(index, 1)
            }
          })
        } else {
          this.customerUsers = [];
          this.customerUsers = Object.assign([], this.originalUserList)
        }
        this.customerUsers = Object.assign([], _.uniqBy(this.customerUsers, 'id'))
        this.checkValueUpdateInputTage();
      });
    } else {
      const selected = _.filter(this.originalSelectedWatcher, {
        serviceTicketId: this.newWatcher
      });
      if (this.originalUserList && this.originalUserList.length > 0) {
        this.originalUserList.forEach((orgUser: any) => {
          selected.forEach((element: any) => {
            if (orgUser.value.id === element.watcherId) {
              this.watchers.push({ value: orgUser.value, display: orgUser.value.email, id: orgUser.value.id });
            }
          });
        });
      }
      if (this.watchers.length > 0) {
        this.customerUsers.forEach((custElement: any, index: any) => {
          if (this.watchers.some((x: any) => x.id === custElement.id)) {
            this.customerUsers.splice(index, 1)
          }
        })
      } else {
        this.customerUsers = [];
        this.customerUsers = Object.assign([], this.originalUserList)
      }
      this.customerUsers = Object.assign([], _.uniqBy(this.customerUsers, 'id'))
      this.checkValueUpdateInputTage();
    }
  }

  checkValueUpdateInputTage() {
    let displayValue = "";
    if (this.watchers.length > 0) {
      this.watchers.forEach((element: any) => {
        const name = ((element.value.name) ? (element.value.name) : (element.name));
        const email = ((element.value.email) ? (element.value.email) : (element.email));
        if (name && email) {
          displayValue = displayValue + name + '(' + email + ')' + '\n';
        } else if (email) {
          displayValue = displayValue + '(' + email + ')' + '\n';
        } else if (name) {
          displayValue = displayValue + name + '\n';
        } else {
          displayValue = ""
        }
      })
      if (displayValue) {
        this.filterFieldForm.controls['value'].setValue(displayValue);
      }
    } else if (this.filterFieldForm !== undefined) {
      this.filterFieldForm.controls['value'].setValue(displayValue = "");
    }
  }

  updateOptionsForRequester(data: any, isUpdateRec: boolean = true) {
    data.options.forEach((x: any) => {
      if (x.user.isActive) {
        this.selectOptions.push({ label: x.name || x.email, value: x.user.id, isActive: x.user.isActive });
      }
    });
    if (this.details && this.data.comp === 'ticket' && this.data.key === 'requesterById') {
      if (isUpdateRec && this.selectOptions.filter(x => x.value !== this.details.requesterById)) {
        this.selectOptions.push({ label: this.details.requester, value: this.details.requesterById, isActive: false });
      } else {
        this.selectOptions.forEach((element, index) => {
          if (!element.isActive) {
            this.selectOptions.splice(index, 1)
          }
        })
      }
    }
    this.selectOptions = _.uniqBy(this.selectOptions, function (e) {
      return e.label;
    });
    this.selectOptions = _.orderBy(this.selectOptions, ['label'], ['asc']); // Use Lodash to sort array by 'name'

  }

  checkValuesForNumber(e: any) {
    if (this.data.key === 'coverageDirection') {
      if (e.metaKey || e.ctrlKey) {
        return true;
      }
      if (e.which === 32) {
        return false;
      }
      if (e.which === 0) {
        return true;
      }
      if (e.which < 33) {
        return true;
      }
      const input = String.fromCharCode(e.which);
      return !!/[\d\s]/.test(input);
    }
    return;
  }

  setNetworkSwitchDropdown(res: any, value: any) {
    const netWorkSwitches = _.orderBy(res, ['name']);
    this.selectOptions.push({
      value: null,
      label: 'No Hub Selected',
      networkSwitchLinkName: null,
      floorName: null
    });
    netWorkSwitches.forEach(element => {
      if (this.data.id !== element.id) {
        this.selectOptions.push({
          value: element.id,
          label: element.name + ' (' + element.floorName + ')',
          networkSwitchLinkName: element.name,
          floorName: element.floorName
        });
      }
    });
    this.editFeild(value);
    this.disabled = false;
  }

  setAssgineUserDropdown(res: any, value: any) {
    const assignees = _.orderBy(res, ['name']);
    this.selectOptions.push({ label: 'Unassigned', value: null });
    assignees.forEach(element => {
      this.selectOptions.push({
        value: element.id,
        label: element.name || element.email,
      });
    })
    if (this.details && this.details.assignedTo !== null && this.selectOptions.filter(e => e.value === this.details.assignedTo.id).length === 0) {
      this.selectOptions.push({
        value: this.details.assignedTo.id,
        label: this.details.assignedTo.name || this.details.assignedTo.email,
      });
    }

    this.editFeild(value);
    this.disabled = false;
  }

  updateDeviceStatusName(status: any) {
    if (this.deviceStatusData !== undefined) {
      return Utilities.updateDisplayStatus(this.deviceStatusData, status);
    }
  }
  validDecimalNumber(f: any) {
    if (isNaN(f)) {
      return true;
    } else {
      return false;
    }

    if (!f.value) { // Test if text field is empty
      f.value = 0; // Initialize value to 0
      console.log("Valid number"); // Let user know input is OK
    } else if (!/^\d+(\.\d{1,2})?$/.test(f.value)) { // Test regex
      f.value = ""; // Empty the text box
      console.error("invalid number"); // Let user know input is not OK
    } else {
      console.log("Valid number"); // Let user know input is OK
    }
  }
}