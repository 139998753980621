import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';
import { Environment } from '@SiteOwl/core';

@Directive({
  selector: '[appRetryImage]'
})
export class RetryImageDirective {
  @Input() appRetryImage: string = ''; // Original image URL
  @Input() retryLimit: number = 30; // Maximum retry attempts
  @Input() retryDelay: number = 1000; // Delay between retries (in milliseconds)
  @Output() loadError = new EventEmitter();

  private retryCount = 0;

  constructor(private el: ElementRef, private renderer: Renderer2, private env: Environment) {}

  @HostListener('error') onError() {
    if (this.retryCount < this.retryLimit) {
      this.retryCount++;
      setTimeout(() => {
        this.reloadImage();
      }, this.retryDelay);
    } else {
      console.error('Image failed to load after retries:', this.appRetryImage);
      this.renderer.setAttribute(
        this.el.nativeElement,
        'src',
        this.env.cdnUrl + 'assets/images/no-image.png' // Fallback image if retries fail
      );
      this.loadError.emit();
      
    }
  }

  @HostListener('load') onLoad() {
    // Reset the retry count on successful load
    this.retryCount = 0;
  }

  private reloadImage() {
    const imgElement = this.el.nativeElement as HTMLImageElement;
    this.renderer.setAttribute(imgElement, 'src', '');
    this.renderer.setAttribute(imgElement, 'src', this.appRetryImage);
  }
}
